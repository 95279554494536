.root {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    border: solid 1px #ccc;
    border-radius: .25em;
}

.button {
    display: block;
    border: none;
    background: transparent;
    padding: 0;
    cursor: pointer;
}

.icon {
    display: block;
    width: .75em;
}

.carousel {
    width: 10rem;
    border-left: solid 1px #ccc;
    border-right: solid 1px #ccc;
}

.carouselItem {
    width: 10rem;
    text-align: center;
    font-size: .9rem;
}
