.user {

}

.userButton {
    text-transform: none;
    cursor: pointer;
}

.userButtonLabel {
    display: flex;
    flex-direction: column;
}

.userName {
    font-weight: 400;
    text-align: left;
}

.companyTitle {
    font-weight: 700;
    font-size: .75rem;
    margin: 0;
    word-break: break-word;
    text-align: left;
}

.avatar {
    width: 1.8rem !important;
    height: 1.8rem !important;
}

.menuItem {
    color: rgba(0, 0, 0, 0.87) !important;
}