.layout {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 1;
    min-height: 100vh;
    background-color: #fafafa;
}

.appFrame {
    display: flex;
    flex-grow: 1;
    overflow: auto;
}

.appBar {
    justify-content: center;
    width: auto !important;
    height: var(--appbar-height) !important;
    left: calc(var(--sidebar-width) * 1px) !important;
    transition: left calc(var(--transitions-duration-leavingScreen) * 1ms) var(--transitions-easing-sharp) !important;
    background-color: #fff !important;
}

.appSidebarClosed .appBar {
    left: calc(var(--sidebar-closedWidth) * 1px) !important;
}

.toolbar {
    padding-left: 24px;
}

.appSidebar {
    position: relative;
    height: 100%;
    border-radius: 0 !important;
    padding: calc(var(--spacing) * 4);
}
.appSidebarClosed .appSidebar {
    padding: calc(var(--spacing) * 3);
}

.content {
    margin-left: auto;
    margin-right: auto;
    max-width: calc(1420px + var(--spacing) * 10);
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    padding: calc(var(--spacing) * 5);
    margin-top: var(--appbar-height);
}
