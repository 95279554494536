div.overview {
    display: flex;
    width: 100%;
    flex-direction: column;
}

div.overviewGraph {
    flex: 1;
}

div.overviewStats {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    width: 100%;
}

@media(min-width: 1024px) {
    div.overview {
        flex-direction: row;
    }

    div.overviewStats {
        width: 33%;
    }
}
