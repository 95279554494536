.weighingTicketChart {
    font-size: 14px;
}

.chartLabel {
    fill: rgba(0, 0, 0, 0.54);
    font-weight: 500;
    font-size: 16px;
}

/*.yAxis {*/
/*    font-size: 14px;*/
/*}*/
