.container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    background: #fff;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.icon {
    width: 5rem;
    height: 5rem
}