.logo {
    position: relative;
    padding-bottom: calc(var(--spacing) * 4);
    /*height: var(--appbar-height);*/
}

.logoImg {
    position: absolute;
    height: 0;
    opacity: 0;
    transition: all calc(var(--transitions-duration-leavingScreen) * 1ms);
}

.sidebar {
    width: 85px;
    transition: width .2s !important;
    overflow: hidden;
}

.sidebar.sidebarOpen {
    width: 268px;
}

.sidebar.sidebarOpen .logoImg {
    position: relative;
    opacity: 1;
    height: auto;
    transition: all .2s calc(var(--transitions-duration-leavingScreen) * 1ms);
}

.logoImgSmall {
    max-width: 100%;
    height: auto;
    opacity: 1;
    transition: all .2s calc(var(--transitions-duration-leavingScreen) * 1ms);
}
.sidebar.sidebarOpen .logoImgSmall {
    position: absolute;
    height: 0;
    opacity: 0;
    transition: all calc(var(--transitions-duration-leavingScreen) * 1ms);
}
